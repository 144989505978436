import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import NavData from '../config/data/nav/nav_data.json';
import content from '../config/data/table/content.csv';
import boardCustomType from '../config/data/table/boardCustomType.csv';
import categoryType from '../config/data/table/categoryType.csv';
import setTableDataFile from '../localization/data_read';
import * as URL from '../constant/kollusUrl';

import './css/app.scss';

import TopBanner from './components/layout/topBanner';

const CommonHeader = lazy(() => import('./components/common/header'));
const CommonFooter = lazy(() => import('./components/common/common_footer'));

const Main = lazy(() => import('./pages/home'));
const SearchMain = lazy(() => import('./components/search/search_main'));
const Calamity = lazy(() => import('./components/calamity/Calamity'));
const BusinessGlobal = lazy(() => import('./components/business/BusinessGlobal'));
const NotFound = lazy(() => import('./not_found'));

const LiveMain = lazy(() => import('./components/live/live_main'));
const ScheduleMain = lazy(() => import('./components/schedule/schedule_main'));

const NewsMain = lazy(() => import('./components/news/news_main'));
const NewsGenre = lazy(() => import('./components/news/news_genre'));
const NewsKogl = lazy(() => import('./components/news/news_kogl'));
const NewsProgram = lazy(() => import('./components/news/news_program'));
const NewsCorner = lazy(() => import('./components/news/news_corner'));
const NewsView = lazy(() => import('./components/news/news_view'));
const NewsReporterMain = lazy(() => import('./components/news/news_reporter_main'));
const NewsReporter = lazy(() => import('./components/news/news_reporter'));
const NewsVideo = lazy(() => import('./components/news/news_video'));
// const NewsVideoView = lazy(() => import('./components/news/news_video_view'));
const NewsVideoView = lazy(() => import('./components/news/video-view'));

const TvMain = lazy(() => import('./components/tv/tv_main'));
const TvProgram = lazy(() => import('./components/tv/tv_program'));

const RadioMain = lazy(() => import('./components/radio/radio_main'));
const RadioVod = lazy(() => import('./components/radio/radio_vod_main'));
const RadioVodView = lazy(() => import('./components/radio/radio_vod_view'));
const RadioPodcast = lazy(() => import('./components/radio/radio_podcast'));
const RadioPodcastList = lazy(() => import('./components/radio/radio_podcast_list'));
const RadioPodcastEpisode = lazy(() => import('./components/radio/radio_podcast_episode'));
const RadioProgram = lazy(() => import('./components/radio/radio_program'));
const RadioCategories = lazy(() => import('./components/radio/radio_categories'));

const VodMain = lazy(() => import('./components/vod/vod_main'));
const VodView = lazy(() => import('./components/vod/vod_view'));

const App = () => {
  let nav = NavData;
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state);

  const [isRadioPlay, setIsRadioPlay] = useState({
    watch: false,
    radioPlayed: false,
    podcastPlayed: false,
    footerPlayed: false,
    footerRef: {},
    vod: false,
    aod: [],
    aodController: [],
    contentKey: [],
    footerAudioUrl: URL.LIVE_RADIO,
    playPercent: 0,
    playPosition: 0,
    playDuration: 0,
  });

  const [liveSort, setLiveSort] = useState();

  useQuery('content', () => setTableDataFile(content), { staleTime: Infinity, cacheTime: Infinity });
  useQuery('boardCustomType', () => setTableDataFile(boardCustomType), { staleTime: Infinity, cacheTime: Infinity });
  useQuery('categoryType', () => setTableDataFile(categoryType), { staleTime: Infinity, cacheTime: Infinity });
  useQuery(['global', 'anchor', 'title'], () => (mode === 'en' ? 'New window opened' : '새 창 열림'), {
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    enabled: !!mode,
  });

  function throttle(callback, delay) {
    let timer = null;
    return () => {
      if (timer) return;
      timer = setTimeout(() => {
        callback.apply(this);
        timer = null;
      }, delay);
    };
  }

  useEffect(() => {
    sessionStorage.setItem('lang', mode);
    i18n.changeLanguage(mode);
  }, [mode]);

  useEffect(() => {
    if (window) {
      const search = new URLSearchParams(window.location.search);
      const firstLang = search.get('lang');

      if (firstLang) dispatch({ type: 'CHANGE_MODE', mode: firstLang });
    }
  }, []);

  return (
    <Router forceRefresh={false}>
      <Suspense fallback={null}>
        <div className="app">
          <TopBanner />
          <CommonHeader
            throttle={throttle}
            mode={mode}
            isRadioPlay={isRadioPlay}
            setIsRadioPlay={setIsRadioPlay}
            liveSort={liveSort}
            setLiveSort={setLiveSort}
          />
          <div className="container">
            <Switch>
              <Route exact path={nav['HOME'].path} component={Main} />
              <Route
                exact
                path={nav['LIVE_MAIN'].path}
                render={(props) => <LiveMain mode={mode} liveSort={liveSort} setLiveSort={setLiveSort} {...props} />}
              />
              <Route exact path={nav['NEWS_MAIN'].path} component={NewsMain} />
              <Route exact path={nav['NEWS_KOGL'].path} render={(props) => <NewsKogl mode={mode} {...props} />} />
              <Route exact path={nav['NEWS_GENRE'].path} render={(props) => <NewsGenre mode={mode} {...props} />} />
              <Route exact path={nav['NEWS_PROGRAM_ID'].path} component={NewsProgram} />
              <Route exact path={nav['NEWS_CORNER'].path} component={NewsCorner} />
              <Route exact path={nav['NEWS_VIEW'].path} render={(props) => <NewsView mode={mode} {...props} />} />
              <Route exact path={nav['NEWS_REPORTER_MAIN'].path} component={NewsReporterMain} />
              <Route exact path={nav['NEWS_REPORTER'].path} component={NewsReporter} />
              <Route exact path={nav['NEWS_VIDEO'].path} component={NewsVideo} />
              <Route
                exact
                path={nav['NEWS_VIDEO_VIEW'].path}
                render={(props) => {
                  const isLegacyUrl = /(id=)|(board=)|(sort=)/g.test(props.location.search);

                  if (isLegacyUrl) {
                    const search = new URLSearchParams(props.location.search);
                    const episodeId = search.get('id');
                    return <Redirect to={`/news/video/${episodeId}`} />;
                  }
                  return <NewsVideoView mode={mode} {...props} />;
                }}
              />
              <Route exact path={nav['TV_MAIN'].path} render={(props) => <TvMain mode={mode} {...props} />} />
              <Route
                path={nav['TV_PROGRAM'].path}
                render={(props) => <TvProgram key={props.match.params.programCode} mode={mode} {...props} />}
              />
              <Route path={nav['TV_PROGRAM_INTRO'].path} component={TvProgram} />
              <Route path={nav['TV_PROGRAM_ARCHIVE'].path} component={TvProgram} />
              <Route path={nav['TV_PROGRAM_VOD'].path} component={TvProgram} />
              <Route path={nav['TV_PROGRAM_ARCHIVE_VIEW'].path} component={TvProgram} />
              <Route path={nav['TV_PROGRAM_BOARD'].path} component={TvProgram} />
              <Route path={nav['TV_PROGRAM_PHOTOGALLERY'].path} component={TvProgram} />
              <Route path={nav['TV_PROGRAM_PHOTOGALLERY_VIEW'].path} component={TvProgram} />
              <Route
                exact
                path={nav['RADIO_MAIN'].path}
                render={(props) => (
                  <RadioMain mode={mode} isRadioPlay={isRadioPlay} setIsRadioPlay={setIsRadioPlay} {...props} />
                )}
              />
              <Route
                exact
                path={nav['RADIO_MAIN_WATCH'].path}
                render={(props) => (
                  <RadioMain mode={mode} isRadioPlay={isRadioPlay} setIsRadioPlay={setIsRadioPlay} {...props} />
                )}
              />
              <Route
                exact
                path={nav['RADIO_MAIN_PODCAST'].path}
                render={(props) => (
                  <RadioMain mode={mode} isRadioPlay={isRadioPlay} setIsRadioPlay={setIsRadioPlay} {...props} />
                )}
              />
              <Route
                exact
                path={nav['RADIO_VOD_MAIN'].path}
                render={(props) => (
                  <RadioVod mode={mode} isRadioPlay={isRadioPlay} setIsRadioPlay={setIsRadioPlay} {...props} />
                )}
              />
              <Route
                exact
                path={nav['RADIO_VOD_MAIN_VIEW'].path}
                render={(props) => (
                  <RadioVod mode={mode} isRadioPlay={isRadioPlay} setIsRadioPlay={setIsRadioPlay} {...props} />
                )}
              />
              <Route
                exact
                path={nav['RADIO_VOD_VIEW'].path}
                render={(props) => (
                  <RadioVodView mode={mode} isRadioPlay={isRadioPlay} setIsRadioPlay={setIsRadioPlay} {...props} />
                )}
              />
              <Route
                exact
                path={nav['RADIO_PODCAST'].path}
                render={(props) => <RadioPodcast mode={mode} {...props} />}
              />
              <Route
                exact
                path={nav['RADIO_PODCAST_LIST'].path}
                render={(props) => (
                  <RadioPodcastList mode={mode} isRadioPlay={isRadioPlay} setIsRadioPlay={setIsRadioPlay} {...props} />
                )}
              />
              <Route
                exact
                path={nav['RADIO_PODCAST_EPISODE'].path}
                render={(props) => (
                  <RadioPodcastEpisode
                    mode={mode}
                    isRadioPlay={isRadioPlay}
                    setIsRadioPlay={setIsRadioPlay}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path={nav['RADIO_CATEGORIES'].path}
                render={(props) => (
                  <RadioCategories mode={mode} isRadioPlay={isRadioPlay} setIsRadioPlay={setIsRadioPlay} {...props} />
                )}
              />
              <Route
                path={nav['RADIO_PROGRAM'].path}
                render={(props) => (
                  <RadioProgram isRadioPlay={isRadioPlay} setIsRadioPlay={setIsRadioPlay} mode={mode} {...props} />
                )}
              />
              <Route exact path={nav['VOD_MAIN'].path} render={(props) => <VodMain mode={mode} {...props} />} />
              <Route exact path={nav['VOD_MAIN_ID'].path} render={(props) => <VodMain mode={mode} {...props} />} />
              <Route
                exact
                path={nav['VOD_VIEW'].path}
                render={(props) => {
                  const search = new URLSearchParams(props.location.search);
                  const currentSort = search.get('sort');

                  const sortMapping = {
                    vodLatest: 'latestVod',
                    vodPopular: 'popularVod',
                    reco: 'recoVod',
                  };

                  if (currentSort in sortMapping) {
                    search.set('sort', sortMapping[currentSort]);
                    return <Redirect to={`/vod/view?${search.toString()}`} />;
                  }
                  return <VodView mode={mode} {...props} />;
                }}
              />
              <Route exact path={nav['BUSINESS_GLOBAL'].path} component={BusinessGlobal} />
              <Route exact path={nav['CALAMITY'].path} render={(props) => <Calamity mode={mode} {...props} />} />
              <Route exact path={nav['SCHEDULE'].path} render={(props) => <ScheduleMain mode={mode} {...props} />} />
              <Route exact path={nav['SEARCH_MAIN'].path} component={SearchMain} />
              <Route component={NotFound} />
            </Switch>
          </div>
          <CommonFooter />
        </div>
      </Suspense>
    </Router>
  );
};

export default App;
