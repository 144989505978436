// for supporting IE
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './routes/app';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './libs';
import './styles/index.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const queryClient = new QueryClient();

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <Helmet>
            <title>Arirang : The Korea International Broadcasting Foundation</title>
            <meta name="description" content="The Korea International Broadcasting Foundation" />
          </Helmet>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);
